import HomeCard from './homeCardComponent';
import { useState } from 'react';
import './homeCardStyle.css'
import MaterialData from '../../material-data';



const cardInfo = [
    // {   
    //     id:'m0',
    //     imageUrl:"https://naturstein-teak.de/cdn/shop/files/TravertinScabasAntikWandverblenderSteinwandNaturstein-5_800x.jpg?v=1705134206",
    //     name: 'Aeussere_Wandbekleidung_Ueber_Terrain'
    // },
    // {   
    //     id:'m1',
    //     imageUrl: "https://cdn.shopify.com/s/files/1/0635/0553/1121/products/Dune_01.jpg?v=1655811745",
    //     name: 'Bauwerk In der Umgebung'
    // },
    {   
        id:'m1',
        imageUrl:"https://www.tugraz.at/fileadmin/user_upload/tugrazInternal/News_Stories/Medienservice/2023/CD-Labor_Grengg/BANNER-CD-Labor-Baustoffe-by-Lunghammer-tugraz-16.jpg",
        name: 'Bauteile_hinzugefügt_von_Studenten'
    },
    {   
        id:'m2',
        imageUrl:"https://www.edl.poerner.de/fileadmin/_processed_/f/8/csm_3D_printed_model_HyKero_16x9_eaaac6af06.jpg",
        name: 'Befoerderungs_Anlage'
    },
    {   
        id:'m3',
        imageUrl:"https://www.fussboden-wolf.de/themes/printeri/images/sl1.jpg",
        name: 'Bodenbelag'
    },
    {   
        id:'m4',
        imageUrl:"https://www.schmitz-peter.de/ab/600_399/2N4A9337.JPG",
        name: 'Deckenbekleidung'
    },
    {   
        id:'m5',
        imageUrl:"https://www.ingenieurbau-online.de/fileadmin/_processed_/3/f/csm_tu-berlin_Kindergarten-innenansicht_Sissach_d5aa69db8f.jpg",
        name: 'Deckenkonstruktion'
    },
    {   
        id:'m6',
        imageUrl:"https://www.pilz.com/imagecache/mam/pilz/content/editors_mm/fittosize__752_0_a24c6b60bac4d6bfa5a5bea0c197f631_f_hannover_messe_psensgate_cold1_2012_04_v2-mobile-1698418345.jpg",
        name: 'Schutzeinrichtung'
    },
    {   
        id:'m7',
        imageUrl:"https://www.bft-international.com/imgs/1/4/0/9/2/5/2/tok_d90407762f099a882fd9d8ac32ea637e/w300_h200_x600_y800_3_ElementwandBaustelle-d8791f3f92e619d0.jpeg",
        name: 'Aussenwand'
    },
    {   
        id:'m7',
        imageUrl:"https://www.hpgasser.ch/wp-content/uploads/2022/10/SoMe-Thumbnail-Hotel-Jaegerhof-Zermatt.jpg",
        name: 'Element zu Dach'
    },
    {   
        id:'m7',
        imageUrl:"https://www.uretek.de/site/assets/files/1788/bodenplatte_blog.jpeg",
        name: 'Bodenplatte'
    },
    // {   
    //     id:'m7',
    //     imageUrl:"https://findig-projekt.de/wp-content/uploads/findig-tga-planung.jpg",
    //     name: 'Gastechnische_Anlage'
    // },
    {   
        id:'m7',
        imageUrl:"https://vorteilnaturnah.tg.ch/public/upload/assets/82011/gru_04_W94A8732_RGB.jpg?fp=1",
        name: 'Gruenflaeche'
    },
    // {   
    //     id:'m8',
    //     imageUrl:"https://images.pexels.com/photos/7931/pexels-photo-7931.jpg?cs=srgb&dl=pexels-pixabay-7931.jpg&fm=jpg",
    //     name: 'Hartflaeche'
    // },
    {   
        id:'m9',
        imageUrl:"https://www.shipbob.com/wp-content/uploads/2021/07/inventory-vs-stock-.jpg",
        name: 'Kleininventar'
    },
    {   
        id:'m10',
        imageUrl:"https://static.wixstatic.com/media/62adbcec8fc243d0b710789bb01af4a1.jpg/v1/fill/w_640,h_660,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/62adbcec8fc243d0b710789bb01af4a1.jpgg",
        name: 'Lufttechnische Anlage'
    },
    {   
        id:'m11',
        imageUrl:"https://cooee.eu/wp-content/uploads/sites/2/2022/09/Mo%CC%88bler.jpg",
        name: 'Mobiliar'
    },
    // {   
    //     id:'m11',
    //     imageUrl:"https://secur.ch/wp-content/uploads/2022/10/sicherheit-an-ihrem-objekt-ausgangssituation-und-optionen.jpg",
    //     name: 'Sicherheit_Anlage'
    // },
    {   
        id:'m12',
        imageUrl:"https://www.dbz.de/imgs/102489628_67fe1735e1.jpg",
        name: 'Stuetzenkonstruktion'
    },
    {   
        id:'m11',
        imageUrl:"https://d2ge4kewizl1vk.cloudfront.net/app/uploads/2021/09/13135151/Sheer-Force_Linen-curtain-and-sheer-curtain-fabrics-What-you-need-to-know-about-these-dreamy-fabric-options.jpg",
        name: 'Textilien'
    },
    {   
        id:'m13',
        imageUrl:"https://media.bahag.com/assets/11/77/117723_17530352.jpg",
        name: 'Trennwand'
    },
    {   
        id:'m14',
        imageUrl:"https://www.advancedcooling.co.uk/wp-content/uploads/2023/01/Temperature-Control-3.jpg",
        name: 'Waermetechnische Anlage'
    },
    {   
        id:'m15',
        imageUrl:"https://www.pbb-engineering.de/files/cto_layout/img/leistungen/Nutzungsspezifische_Anlagen.jpg",
        name: 'Nutzungsspezifische Anlage'
    },
    {   
        id:'m16',
        imageUrl:"https://shop.beendorfer-massivholz.de/media/catalog/10/5c5b6f4149505398b938e4644dd915b2.jpg",
        name: 'Wandbekleidung'
    },
    {   
        id:'m16',
        imageUrl:"https://www.bauprofessor.de/tools/cmsimage.ashx?id=f29185b4-2412-494d-b2f3-503967789ffe",
        name: 'Wandkonstruktion'
    },
    {   
        id:'m16',
        imageUrl:"https://www.roka-wasseraufbereitung.de/wp-content/uploads/2017/05/startslider-01a-1500x750.jpg",
        name: 'Wassertechnische_Anlage'
    },
    {   
        id:'m16',
        imageUrl:"https://www.roka-wasseraufbereitung.de/wp-content/uploads/2017/05/startslider-01a-1500x750.jpg",
        name: 'Wassertechnische_Anlage'
    },

]


const HomeCards = () =>{

    const [expandedIndex, setExpandedIndex] = useState({upperbound: 5, lowerbound:0});
    const [showArrow, setshowArrow] = useState({right: true, left:false});


   const scrollHandler = (direction)=>{
    switch(direction){
        case 'Right':
            if(expandedIndex.upperbound< cardInfo.length){
                setExpandedIndex({...expandedIndex,upperbound: expandedIndex.upperbound +1, lowerbound: expandedIndex.lowerbound +1 } )
                setshowArrow({...showArrow,right: !showArrow.right})
            }
            break;
        case 'Left':
            if(expandedIndex.lowerbound> 0){
                setExpandedIndex({...expandedIndex,upperbound: expandedIndex.upperbound -1, lowerbound: expandedIndex.lowerbound -1} )
                setshowArrow({...showArrow,left: !showArrow.left})
            }
            break;
    }
   };



    return(
        <div className='home-component-container'>
            <div className='cards-title'>
                    <h3> Bauteilkatalog des Rueckbauprojekts Roche Sued </h3>
                    <p >
                        Die Roche hat mit Hilfe von Gruner Ingenieure einen Bauteilkatalog erstellt, 
                        in dem ein Grossteil der Bauteile erfasst sind. Die erste Atelierdurchführung im Frühling 2023 zeigte jedoch, 
                        dass es durchaus auch verwendbare Bauteile in den Bauten gibt, die nicht im Katalog erfasst sind. 
                        Beispielsweise Wasserrohre, Kabel, Lueftungsrohre, Teppiche, Eckbleche, Daemmmaterialien, etc. 
                        Aus diesem Grund bedarf es den Beizug weiterer Quellen um die Aufgabe 100% Re-Use-for-living erfüllen zu können.
                    </p>
            </div>
            
            <div className='scroll-wrapper'>
                <div className='left-arrow-container' onClick={()=>scrollHandler('Left')} >&#60;</div> 
                <div className="cards-list">
                    {cardInfo.slice(expandedIndex.lowerbound,expandedIndex.upperbound).map((element)=>(
                        <HomeCard info ={element}/>
                    ))}
                </div>
                <div className='right-arrow-container' onClick={()=>scrollHandler('Right')}>&#62;</div>
            </div>
        </div>

    );
};

export default HomeCards;