import './cart-iconStyle.scss' 
import {ReactComponent as ShoppingIcon} from '../../assets/shopping-bag.svg'
import { useContext } from 'react';

import { CartContext } from '../../context/cartContext';

const CartIcon = () =>{
    const {isCartOpen, setIsCartOpen} = useContext(CartContext);
    const{cartItems} = useContext(CartContext);

    const toggleIsCartOpen = ()=> setIsCartOpen(!isCartOpen)

    return(
        <div className='cart-icon-container' onClick={toggleIsCartOpen}>
            <ShoppingIcon  className='shopping-icon'/>
            <span className='item-count'>{cartItems.length}</span>
        </div>
    )
};

export default CartIcon;