const Segmentation = () =>{
    return(
        <div >
            <h2 className="text-red-600">Segementation Model Place Holder</h2>
            <h3>UNDER CONSTRUCTION</h3>
        </div>
    )
};


export default Segmentation;