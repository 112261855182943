import Button from "../button/buttonComponent";
import { useContext} from "react";
import { CartContext } from "../../context/cartContext";
import './material-cardStyle.scss';
import errorImage from '../../error2.webp'
import { useState } from "react";
import { Link } from "react-router-dom";

const MaterialCard = ({material, title}) =>{
    const {uuid, bauteil_gruner, anzahl, foto1} = material;
    const {addItemToCart} = useContext(CartContext);

    const [showPhoto, setShowPhoto] = useState(true)
    const addProductToCart = ()=> addItemToCart(material);
    const setDefaultPhoto = (event) => {

        event.target.src = errorImage
        event.onerror = null
    }

    const checkImageLoadability = (url) =>{
        const img = new Image()
        img.src = url
        img.onerror = ()=> setShowPhoto(false)    
    };

    // checkImageLoadability(foto1)
    // if(title == "Wandbekleidung"){
    //     return(
    //         <div className="product-card-container">
    //         <Link to={`/shop/${title}/${uuid}`}>
    //             <img src = {foto1} alt = {`${foto1}`} onError={(event)=>setDefaultPhoto(event)} id={uuid}/>
    //         </Link>
    //         <div className="footer">
    //             <span className= {bauteil_gruner}>Name: {bauteil_gruner}</span>
    //             <br></br>
    //             <span className= {anzahl}>In stock: {anzahl}</span>
    //         </div>
    //         <Button buttonType = 'inverted' onClick ={addProductToCart}> Add to cart</Button>
    //     </div>
    //     )
    // }
    return(
        showPhoto &&
        <div className="product-card-container">
            <Link to={`/shop/${title}/${uuid}`}>
                <img src = {foto1} alt = {`${foto1}`} onError={(event)=>setDefaultPhoto(event)} id={uuid}/>
            </Link>
            <div className="footer">
                <span className= {bauteil_gruner}>Name: {bauteil_gruner}</span>
                <br></br>
                <span className= {anzahl}>In stock: {anzahl}</span>
            </div>
            <Button buttonType = 'inverted' onClick ={addProductToCart}> Add to cart</Button>
        </div>
    )
};

export default MaterialCard;