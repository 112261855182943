import { useContext } from "react";
import { CartContext } from "../../context/cartContext";
import Button from "../../components/button/buttonComponent";
import JSZip from "jszip";
import {saveAs} from 'file-saver';

import CheckoutItem from "../../components/checkout-item/checkout-itemComponenet";

import './checkoutStyle.scss'

const CheckOut = ()=>{
    const {cartItems, addItemToCart, removeItemFromCart} = useContext(CartContext);

    const downloadJSON = (items)=>{
        const jsonContent = JSON.stringify(items, null, 2);
        const blob = new Blob([jsonContent], { type: 'application/json' });
        const url = URL.createObjectURL(blob);

        const link = document.createElement('a');
        link.href = url;
        link.download = "Reuse24" || 'data.json';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
    }

    const downloadZip = async(cartitems) =>{

        
        const zip = new JSZip();
        const imgFolder = zip.folder("Reuse_24");
        // const photos = cartitems.map((i) => i.foto1);

        const imagePromises = cartitems.map(async (item, index) => {
            const {foto1} = item;

            const regex1 = /\/DCIM\/(.*)/;
            const regex2 = /\/student_photo\/(.*)/;

            const match = foto1.match(regex1);
            let name;
            if (match === null){
                name = foto1.match(regex2)[1];

            }
            else{
                name = foto1.match(regex1)[1];
            }


            const response = await fetch(foto1);
            const blob = await response.blob();
            imgFolder.file(name, blob, { binary: true });
        });
    
        // Wait for all images to be added
        await Promise.all(imagePromises);
    
        // Generate and download the zip file
        zip.generateAsync({ type: "blob" }).then((content) => {
            saveAs(content, "Photos.zip");
        });

    };

    const download = (cartitems)=>{
        downloadJSON(cartItems)
        downloadZip(cartItems)

    };








    return(
        <div className="checkout-container">
            <h1>Check Out</h1>
            <div className="checkout-header">
                <div className="header-block">
                    <span>Product</span>
                </div>
                <div className="header-block">
                    <span>Quantity</span>
                </div>
                <div className="header-block">
                    <span>Remove</span>
                </div>
            </div>
            
            {cartItems.map(cartItem =>{
                return(
                    <CheckoutItem key = {cartItem.id} cartItem={cartItem}/>
                )
            })}
            <span className="total"></span>
            <div className="button">
                <Button buttonType = 'inverted' onClick ={() =>download(cartItems)}> Export JSON and photos</Button>
            </div>


        </div>
    )
};


export default CheckOut;