

// BFH
// const AeussereWandbekleidungUeberTerrain = require('./data/JSON/aeussere Wandbekleidung ueber Terrain.json')
// const BauwerkInDerUmgebung = require('./data/JSON/Bauwerk in der Umgebung.json')
const NeueCollection = require('./data/JSON/Neue Collection.json')
const Befoerderungsanlage = require('./data/JSON/Befoerderungsanlage.json')
const Bodenbelag = require('./data/JSON/Bodenbelag.json')
const Deckenbekleidung = require('./data/JSON/Deckenbekleidung.json')
const Deckenkonstruktion = require('./data/JSON/Deckenkonstruktion Dachkonstruktion.json')
const Schutzeinrichtung = require('./data/JSON/Einbauten Schutzeinrichtung zu Ausbau.json')
const Aussenwand = require('./data/JSON/Element in Aussenwand.json')
const ElementZuDach = require('./data/JSON/Element zu Dach.json')
const Bodenplatte = require('./data/JSON/Fundament Bodenplatte.json')
// const Gastechnische_Anlage = require('./data/JSON/Gastechnische Anlage.json')
const Gruenflaeche = require('./data/JSON/Gruenflaeche.json')
// const Hartflaeche = require('./data/JSON/Hartflaeche.json')
const Kleininventar = require('./data/JSON/Kleininventar.json')
const Lufttechnische = require('./data/JSON/Lufttechnische Anlage.json')
const Mobiliar = require('./data/JSON/Mobiliar.json')
// const Sicherheit_Anlage = require('./data/JSON/Sicherheitsanlage.json')
const Stuetzenkonstruktion = require('./data/JSON/Stuetzenkonstruktion.json')
const Textilien = require('./data/JSON/Textilien.json')
const Trennwand = require('./data/JSON/Trennwand Innentuer Innentor.json')
const Waermetechnische = require('./data/JSON/Waermetechnische Anlage.json')
const nutzungsspezifische = require('./data/JSON/Weitere nutzungsspezifische Anlage.json')
const Wandbekleidung = require('./data/JSON/Wandbekleidung.json')
const Wandkonstruktion = require('./data/JSON/Wandkonstruktion.json')
const Wassertechnische_Anlage = require('./data/JSON/Waermetechnische Anlage.json')



const MaterialData = 
[

  // {
  //   title: 'Aeussere_Wandbekleidung_Ueber_Terrain',
  //   items: AeussereWandbekleidungUeberTerrain
  // },
  // {
  //   title: 'Bauwerk_In_der_Umgebung',
  //   items: BauwerkInDerUmgebung
  // },
  {
    title: 'Bauteile_hinzugefügt_von_Studenten',
    items: NeueCollection
  },
  {
    title: 'Befoerderungs_Anlage',
    items: Befoerderungsanlage
  },
  {
    title: 'Bodenbelag',
    items: Bodenbelag
  },
  {
    title: 'Deckenbekleidung',
    items: Deckenbekleidung
  },
  {
    title: 'Deckenkonstruktion',
    items: Deckenkonstruktion
  },
  {
    title: 'Schutzeinrichtung',
    items: Schutzeinrichtung
  },
  {
    title: 'Aussenwand',
    items: Aussenwand
  },
  {
    title: 'ElementZuDach',
    items: ElementZuDach
  },
  {
    title: 'Bodenplatte',
    items: Bodenplatte
  },
  // {
  //   title: 'Gastechnische_Anlage',
  //   items: Gastechnische_Anlage
  // },
  {
    title: 'Gruenflaeche',
    items: Gruenflaeche
  },
  // {
  //   title: 'Hartflaeche',
  //   items: Hartflaeche
  // },
  {
    title: 'Kleininventar',
    items: Kleininventar
  },
  {
    title: 'Lufttechnische_Anlage',
    items: Lufttechnische
  },
  {
    title: 'Mobiliar',
    items: Mobiliar
  },
  // {
  //   title: 'Sicherheit_Anlage',
  //   items: Sicherheit_Anlage
  // },
  {
    title: 'Stuetzenkonstruktion',
    items: Stuetzenkonstruktion
  },
  {
    title: 'Textilien',
    items: Textilien
  },
  {
    title: 'Trennwand',
    items: Trennwand
  },
  {
    title: 'Waermetechnische_Anlage',
    items: Waermetechnische
  },
  {
    title: 'Nutzungsspezifische_Anlage',
    items: nutzungsspezifische
  },
  {
    title: 'Wandbekleidung',
    items: Wandbekleidung
  },
  {
    title: 'Wandkonstruktion',
    items: Wandkonstruktion
  },
  {
    title: 'Wassertechnische_Anlage',
    items: Wassertechnische_Anlage
  },

]

export default MaterialData;
