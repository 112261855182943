import './cart-itemStyle.scss';



const CartItem = ({cartItem})=>{
    const {bauteil_gruner, foto1, quantity} = cartItem;
    
    return(
        <div className='cart-item-container'>
            <img src = {foto1} alt = {`${bauteil_gruner}`}/>
            <div className='item-details'>
                <h2>{bauteil_gruner}</h2>
                <span>Amount: {quantity}</span>
            </div>   
        </div>
    )
};

export default CartItem;