import React, { useContext, useState } from "react";
import { ProductsContext } from "../../context/productsContext";
import './uploadPageStyle.scss'


const InputMaterial = ({feature, onChange, value, ...props})=>{
    const numericTypes = ['dimension_height_cm', 'dimension_length_cm', 'dimension_width_cm, quantity','price_euro','co2_saved' , 'weight_kg']
    const optionTypes = ['glazing_type','handles','hinges','door_swing_direction','condition']
    // const specialTypes = ['location', 'colour']

    let type = '';

    if(numericTypes.includes(feature)){
        type = 'number'
    }else{
        type = 'text'
    }


    return(
        <input type = {type} name = {feature} required onChange={onChange} value={value}/>
    );
};


const MaterialTypeButton = ({materialInfo, onClick, MaterialType})=>{
    return(
        materialInfo.map(element =>{
            const {title} = element;
            return(
                <div className='material-type-buttons-container'>
                    <input type = 'checkbox' id ={title} name = {title} value = {title} onClick={onClick} checked={MaterialType===title}/>
                    <label htmlFor={`${title}`} >{title}</label>
                </div>
            )
        })
    )
};


const UploadPage = ()=>{
    const{materialInfo} = useContext(ProductsContext);
    const[MaterialType, setMaterialType] = useState('facade_element');
    const[inputFields, setInputFields] = useState({});

    const showMaterialTypeHandler = (event)=>{
        setMaterialType(event.target.name)
        setInputFields({})
    };

    const onInputFieldChange = (event)=>{
        setInputFields({...inputFields, [event.target.name]:event.target.value})
    };

    const submitFormHandler = async (event)=>{
        const postInfo = JSON.stringify({...inputFields, ['material_type']: MaterialType}, null, 2);
        // setInputFields({});

        // HTTP Post request
        const url = 'https://intense-earth-41031-f97f06069b61.herokuapp.com/upload_material';
        const content = {
            method: "POST",
            headers:{
                "Content-Type":"application/json"
            },
            body: postInfo
        };
        console.log(postInfo)

        await fetch(url, content)
        .then((res)=> res.json())
        .then(data=>console.log(data))
        .catch(err=>console.log('Fetch error', err))

    };



    // Features that do not need to be filled in
    const exception = ['co2_saved(kg/stk)','id', 'price_compared_to_new', 'url', 'total_dimension']
    

    // Features from the exception will be generated by our system instead
    const generation = ['co2_saved(kg/stk)','id', 'price_compared_to_new', 'url', 'total_dimension' , 'model']




    return(
        <div className="upload-page-container">
            <div className="upload-material-selection">
                <h3>Please select a type of material you want to upload to the database</h3>
                <MaterialTypeButton materialInfo={materialInfo} onClick={showMaterialTypeHandler} MaterialType={MaterialType}/>
            </div>
            {materialInfo.map(
                element=>{
                    const {title, items} = element;
                    const features = Object.keys(items[0]).sort()
                    return(
                        MaterialType===title&&
                        <form className="form-container">
                            <h3>Please fill in the following attributes</h3>
                            {features
                            .filter(feature=> !exception.includes(feature))
                            .map(feature=>{
                                return(
                                    <div className="form">
                                        <div className="form-line">
                                            <div className="form-line-left">
                                                <label htmlFor={feature}>{`${feature}: `}</label>
                                            </div>
                                            <div className="form-line-right">
                                                <InputMaterial feature = {feature} required onChange={onInputFieldChange} value = {inputFields[feature] || ''}/>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            )}
                            <input type="button" className ='upload-page-submit' value='Submit material info!' onClick={submitFormHandler}/>
                        </form>

                    )
                })}
        </div>
    );
};

export default UploadPage;